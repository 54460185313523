import Model, { apiToModelTransformer, modelToApiTransformer } from '@/models/base'
import { Api, JsonData } from '@/helpers/api'

export enum PermissionMask { View = 1, Create = 2, Edit = 4, Delete = 8 }
export const MaskDelimiter = '~'
export const RolePrefix = 'ROLE_'

export default class Profile extends Model {
  protected fieldDefinition = {
    [Api.Hub]: {
      aclUserId: 'aclUserId',
      firstName: 'firstName',
      lastName: 'lastName',
      gravatarUrl: 'gravatarUrl',
      primaryOrgId: 'primaryOrgId',
      primaryOrgName: 'primaryOrgName',
      username: 'username',
      perms: 'permissions',
      roles: 'roles'
    }
  }

  private aclUserId = 0
  private firstName = ''
  private lastName = ''
  private gravatarUrl = ''
  private primaryOrgId = 0
  private primaryOrgName = ''
  private username = ''
  private permissions: string[] = []
  private roles: string[] = []

  getAclUserId () {
    return this.aclUserId
  }

  setAclUserId (aclUserId: number) {
    this.aclUserId = aclUserId

    return this
  }

  getFirstName () {
    return this.firstName
  }

  setFirstName (firstName: string) {
    this.firstName = firstName

    return this
  }

  getLastName () {
    return this.lastName
  }

  setLastName (lastName: string) {
    this.lastName = lastName

    return this
  }

  getInitials () {
    return `${this.firstName[0]}${this.lastName[0]}`
  }

  getGravatarUrl () {
    return this.gravatarUrl
  }

  setGravatarUrl (gravatarUrl: string) {
    this.gravatarUrl = gravatarUrl

    return this
  }

  getPrimaryOrgId () {
    return this.primaryOrgId
  }

  setPrimaryOrgId (primaryOrgId: number) {
    this.primaryOrgId = primaryOrgId

    return this
  }

  getPrimaryOrgName () {
    return this.primaryOrgName
  }

  setPrimaryOrgName (primaryOrgName: string) {
    this.primaryOrgName = primaryOrgName

    return this
  }

  getUsername () {
    return this.username
  }

  setUsername (username: string) {
    this.username = username

    return this
  }

  getPermissions () {
    return this.permissions
  }

  setPermissions (permissions: string[]) {
    this.permissions = permissions

    return this
  }

  getRoles () {
    return this.roles
  }

  setRoles (roles: string[]) {
    this.roles = roles

    return this
  }

  hasPermission (name: string, mask: PermissionMask = PermissionMask.View): boolean {
    const permission = this.permissions.find(
      (permission) => {
        return permission.startsWith(name)
      }
    )

    if (!permission) {
      return false
    }

    const userMask = permission.split(MaskDelimiter)[1]

    if (!userMask) {
      return false
    }

    const result = parseInt(userMask) & mask

    return !(result < 0 || result !== mask)
  }

  hasRole (name: string): boolean {
    const role = this.roles.find(
      (role) => {
        return role === `${RolePrefix}${name}`
      }
    )

    return !!role
  }

  toApiTransformer (api: Api): JsonData | undefined {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }

  fromApiTransformer (data: JsonData, api: Api): Profile {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }
}
