import Model, { apiToModelTransformer, modelToApiTransformer } from '@/models/base'
import { Api, JsonData } from '@/helpers/api'

export enum Currency { GBP, USD, EUR }

export default class Installation extends Model {
  protected fieldDefinition = {
    [Api.Hub]: {
      igrp: 'installationId',
      igrpName: 'installationName',
      currency: 'currency',
      tz: 'timeZone',
      conversationAnalytics: 'conversationAnalytics'
    }
  }

  private installationId = ''
  private installationName = ''
  private currency = Currency.GBP
  private timeZone = ''
  private conversationAnalytics = '0'

  getInstallationId (): string {
    return this.installationId
  }

  setInstallationId (installationId: string) {
    this.installationId = installationId

    return this
  }

  getInstallationName (): string {
    return this.installationName
  }

  setInstallationName (installationName: string) {
    this.installationName = installationName

    return this
  }

  getCurrency (): Currency {
    return this.currency
  }

  setCurrency (currency: Currency) {
    this.currency = currency

    return this
  }

  getTimeZone (): string {
    return this.timeZone
  }

  setTimeZone (timeZone: string) {
    this.timeZone = timeZone

    return this
  }

  getConversationAnalytics (): string {
    return this.conversationAnalytics
  }

  setConversationAnalytics (conversationAnalytics: string) {
    this.conversationAnalytics = conversationAnalytics

    return this
  }

  get hasConversationAnalytics (): boolean {
    return this.conversationAnalytics === '1'
  }

  toApiTransformer (api: Api): JsonData | undefined {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }

  fromApiTransformer (data: JsonData, api: Api): Installation {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }
}
