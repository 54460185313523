import { FilterCollection } from 'mfe-shared/app/src/helpers/filter'
import { Api } from '@/helpers/api'
import Repository, { DefaultLimit } from '@/repos/base'
import Installation from '@/models/installation'

const SingleAccessProperty = 'igrp'
const MultiAccessProperty = 'igrps'

export default class InstallationRepo extends Repository {
  constructor () {
    super(Api.Hub, 'igrps', Installation)
  }

  async fetch (filterCollection: FilterCollection | null = null, limit = DefaultLimit, offset = 0, isStarred = false): Promise<Installation[]> {
    this.getter = MultiAccessProperty

    this.additionalParams = {
      isStarred
    }

    const data = await super.fetch(filterCollection, limit, offset)
    const installations = []

    for (const model of data) {
      if (model instanceof Installation) {
        installations.push(model)
      }
    }

    return installations
  }

  async find (id: string | number): Promise<Installation | null> {
    this.getter = SingleAccessProperty

    const model = await super.find(id)

    if (!(model instanceof Installation)) {
      return null
    }

    return model
  }
}
