import { Api } from '@/helpers/api'
import Repository from '@/repos/base'
import Profile from '@/models/profile'

export default class ProfileRepo extends Repository {
  constructor () {
    super(Api.Hub, 'user', Profile, 'profile')
  }

  async find (): Promise<Profile | null> {
    const model = await super.find('profile')

    if (!(model instanceof Profile)) {
      return null
    }

    return model
  }
}
