














































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Installation from '@/models/installation'
import { Filter, FilterCollection, FilterOperand } from 'mfe-shared/app/src/helpers/filter'
import InstallationRepo from '@/repos/installation'
import { BButton, BFormInput, BSpinner, ListGroupPlugin } from 'bootstrap-vue'

Vue.use(ListGroupPlugin)

@Component({
  components: {
    BButton,
    BFormInput,
    BSpinner
  }
})
export default class InstallationPicker extends Vue {
  @Prop({ type: String, default: 'left' }) align?: string
  private installations: Installation[] = []
  private starredInstallations: Installation[] = []

  private installationRepo = new InstallationRepo()
  private isStarredLoading = true
  private isLoading: boolean | null = null
  private isOpen = false
  private searchQuery = ''

  async mounted () {
    await this.getStarredInstallations()
    this.$nextTick(() => {
      this.$forceUpdate()
    })
  }

  async getStarredInstallations () {
    this.starredInstallations = await this.installationRepo.fetch(
      new FilterCollection(
        new Filter('igrp', FilterOperand.NotEquals, this.$authService.getInstallation().getInstallationId())
      ),
      0,
      0,
      true
    )

    this.isStarredLoading = false
  }

  get starredInstallationIds () {
    return this.starredInstallations.map(
      (installation) => {
        return installation.getInstallationId()
      }
    )
  }

  get dropDownName (): string {
    if (this.$authService.getInstallation()) {
      return this.$authService.getInstallation().getInstallationName()
    }

    return 'Select your installation'
  }

  doSelectInstallation (installation: Installation) {
    this.$authService.selectInstallation(installation)
    this.$emit('selected', installation)
    this.$router.go(0)
  }

  async doSearch () {
    if (this.searchQuery.length > 3) {
      this.isLoading = true

      this.installations = await this.installationRepo.fetch(
        new FilterCollection()
          .addFilter(
            new Filter('igrpName', FilterOperand.IncludesI, this.searchQuery)
          ),
        25
      )

      this.isLoading = false
    }

    if (!this.searchQuery) {
      this.installations = []
    }
  }
}
