import { FilterCollection } from 'mfe-shared/app/src/helpers/filter'
import { Api } from '@/helpers/api'
import { AuthUtil } from '@/utils/auth'
import Repository, { DefaultLimit } from '@/repos/base'
import ReportSection from '@/models/reportSection'

const DEFAULT_BUSINESS_UNIT_ID = 1

export default class ReportSectionRepo extends Repository {
  constructor () {
    super(Api.Hub, `igrps/${AuthUtil.installationId}/navigation`, ReportSection, 'reportSections')
  }

  async fetch (filterCollection: FilterCollection | null = null, limit = DefaultLimit, offset = 0): Promise<ReportSection[]> {
    const data = await super.fetch(filterCollection, limit, offset)
    const reportSections = []

    for (const model of data) {
      if (model instanceof ReportSection) {
        reportSections.push(model)
      }
    }

    return reportSections.filter(
      (reportSection) => {
        return reportSection.getBusinessUnitId() === DEFAULT_BUSINESS_UNIT_ID
      }
    )
  }
}
