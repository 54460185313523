import { FilterCollection } from 'mfe-shared/app/src/helpers/filter'
import Model from '@/models/base'
import { Api, JsonData } from '@/helpers/api'
import { AuthUtil } from '@/utils/auth'
import axios from 'axios'

export const DefaultLimit = 25

export type ApiParams = { [k: string]: string | number | boolean | string[] | number[] | null }

export enum SortDirection { Asc = 'asc', Desc = 'desc' }

export default class Repository {
  protected api: Api
  protected apiRoute: string
  protected Model: { new(): Model }
  protected getter: string | null
  protected setter: string | null
  protected additionalParams: ApiParams = {}
  protected sorts: string[] = []

  constructor (api: Api, apiRoute: string, model: { new(): Model }, getter: string | null = null, setter: string | null = null) {
    this.api = api
    this.apiRoute = apiRoute
    this.Model = model
    this.getter = getter
    this.setter = setter
  }

  async fetch (filterCollection: FilterCollection | null = null, limit = DefaultLimit, offset = 0): Promise<Model[]> {
    const data = []

    const params: { [k: string]: string | number | string[] } = {
      limit,
      offset,
      ...this.additionalParams
    }

    if (filterCollection) {
      params.filters = filterCollection.toApi()
    }

    if (this.sorts) {
      params.sorts = this.sorts
    }

    const response = await axios.get(`${this.api}/${this.apiRoute}`, {
      ...{
        params
      },
      ...this.requestConfig
    })

    if (response && response.data) {
      let items = response.data

      if (this.getter !== null) {
        items = items[this.getter]
      }

      if (response.data && items) {
        for (const item of items) {
          data.push(
            new this.Model().fromApiTransformer(item, this.api)
          )
        }
      }

      this.resetParams()
    }

    return data
  }

  async find (id: string | number, path: string | null = null): Promise<Model | null> {
    const response = await axios.get(`${this.api}/${this.apiRoute}/${id}${path ? `/${path}` : ''}`, {
      ...{
        params: this.additionalParams
      },
      ...this.requestConfig
    })

    let data = response.data

    if (this.getter !== null) {
      data = data[this.getter]
    }

    if (!data) {
      return null
    }

    this.resetParams()

    return new this.Model().fromApiTransformer(data, this.api)
  }

  async create (model: Model, getter: string | null = null): Promise<JsonData | null> {
    let data: JsonData | { [k: string]: JsonData | undefined } | undefined = model.toApiTransformer(this.api)

    if (this.setter !== null) {
      data = {
        [this.setter]: data
      }
    }

    const response = await axios.post(`${this.api}/${this.apiRoute}`, data, {
      params: this.additionalParams,
      ...this.requestConfig
    })

    if (!response.data) {
      return null
    }

    this.resetParams()

    if (getter) {
      return response.data[getter]
    }

    return response.data
  }

  async postBulk (models: Model[], getter: string | null = null): Promise<JsonData | null> {
    const data = []

    for (const model of models) {
      data.push(model.toApiTransformer(this.api))
    }

    const response = await axios.post(`${this.api}/${this.apiRoute}`, data, {
      params: this.additionalParams,
      ...this.requestConfig
    })

    if (!response.data) {
      return null
    }

    this.resetParams()

    if (getter) {
      return response.data[getter]
    }

    return response.data
  }

  withParams (params: ApiParams) {
    this.additionalParams = params

    return this
  }

  sortBy (fieldName: string, direction: SortDirection) {
    this.sorts.push(`${fieldName}-${direction}`)

    return this
  }

  protected get requestConfig (): { headers: { [k: string]: string | null } } | null {
    if (!AuthUtil.authenticatedHeaders) {
      return null
    }

    return {
      headers: AuthUtil.authenticatedHeaders[this.api]
    }
  }

  private resetParams () {
    this.sorts = []
    this.additionalParams = {}
  }
}
