




































































import { Component, Vue } from 'vue-property-decorator'
import { MfaError } from '@/utils/auth'
import HubLogo from '@/components/layout/HubLogo.vue'
import {
  BOverlay,
  BSpinner,
  ButtonPlugin,
  CardPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  LayoutPlugin
} from 'bootstrap-vue'

Vue.use(LayoutPlugin)
Vue.use(FormPlugin)
Vue.use(CardPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)

@Component({
  components: {
    HubLogo,
    BSpinner,
    BOverlay
  }
})
export default class Login extends Vue {
  username = '';
  password = '';
  verificationCode = '';
  formState: boolean | null = null
  mfaState: boolean | null = null
  mfaRequired: boolean | null = null
  isLoading = false

  async onSubmit () {
    this.isLoading = true

    try {
      await this.$authService.login(this.username, this.password, this.mfaRequired ? this.verificationCode : null)
      await this.$router.push('/')
    } catch (e) {
      const isMfaError = e instanceof MfaError
      if (this.mfaRequired && !isMfaError) {
        this.mfaState = false
        this.formState = true
      } else {
        this.formState = isMfaError
        this.mfaRequired = isMfaError
      }
    }

    this.isLoading = false
  }

  onReset () {
    this.username = ''
    this.password = ''
    this.verificationCode = ''
    this.formState = null
    this.mfaState = null
    this.mfaRequired = null
  }
}
