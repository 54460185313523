import Profile from '@/models/profile'
import Installation from '@/models/installation'
import ReportSection from '@/models/reportSection'
import { AuthUtil } from '@/utils/auth'
import InstallationRepo from '@/repos/installation'
import ProfileRepo from '@/repos/profile'
import ReportSectionRepo from '@/repos/reportSection'

export default class AuthService {
  private installationRepo = new InstallationRepo()
  private reportSectionRepo = new ReportSectionRepo()
  private profileRepo = new ProfileRepo()
  private profile = new Profile()
  private installation = new Installation()
  private reportSections: ReportSection[] = []
  private booted = false

  get isAuthenticated (): boolean {
    return AuthUtil.isAuthenticated
  }

  getProfile (): Profile {
    return this.profile
  }

  get hasBooted () {
    return this.booted
  }

  getInstallation (): Installation {
    return this.installation
  }

  getReportSections (): ReportSection[] {
    return this.reportSections
  }

  async boot () {
    if (AuthUtil.accessToken) {
      const profile = await this.profileRepo.find()
      if (profile) {
        this.profile = profile
      }

      await this.loadInstallation()
      this.reportSections = await this.reportSectionRepo.fetch()
      this.booted = true
    }
  }

  async login (username: string, password: string, verificationCode: string | null) {
    await AuthUtil.authenticate(username, password, verificationCode)
    await this.boot()
  }

  async logout () {
    this.profile = new Profile()
    this.installation = new Installation()
    this.reportSections = []
    this.booted = false
    await AuthUtil.logout()
  }

  async loadInstallation (id: string | null = null) {
    id = id || AuthUtil.installationId
    let installation = null

    if (id) {
      installation = await this.installationRepo.find(id)
    }

    if (installation === null) {
      const installations = await this.installationRepo.fetch(null, 1)

      if (installations[0]) {
        installation = installations[0]
      }
    }

    if (installation === null) {
      throw new Error('Could not load installation for user.')
    }

    this.selectInstallation(installation)
  }

  selectInstallation (installation: Installation) {
    this.installation = installation
    AuthUtil.setInstallationId(installation.getInstallationId())
    AuthUtil.setInstallationTimeZone(installation.getTimeZone())

    // Re-init report section repo since installation may changed
    this.reportSectionRepo = new ReportSectionRepo()
  }
}
