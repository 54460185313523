import Model, { apiToModelTransformer } from '@/models/base'
import { Api, JsonData } from '@/helpers/api'
import ReportPage from '@/models/reportPage'

export default class ReportSection extends Model {
  protected fieldDefinition = {
    [Api.Hub]: {
      id: 'id',
      icon: 'icon',
      name: 'name'
    }
  }

  private id = 0
  private businessUnitId = 0
  private icon = ''
  private name = ''
  private reportPages: ReportPage[] = []

  getId () {
    return this.id
  }

  setId (id: number) {
    this.id = id

    return this
  }

  getBusinessUnitId () {
    return this.businessUnitId
  }

  setBusinessUnitId (businessUnitId: number) {
    this.businessUnitId = businessUnitId

    return this
  }

  getIcon () {
    return this.icon
  }

  setIcon (icon: string) {
    this.icon = icon

    return this
  }

  getName () {
    return this.name
  }

  setName (name: string) {
    this.name = name

    return this
  }

  getReportPages () {
    return this.reportPages
  }

  setReportPages (reportPages: ReportPage[]) {
    this.reportPages = reportPages

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return undefined
  }

  fromApiTransformer (data: JsonData, api: Api): ReportSection {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    if (Array.isArray(data.reportPages)) {
      for (const reportPage of data.reportPages) {
        this.reportPages.push(
          new ReportPage()
            .fromApiTransformer(reportPage, api)
        )
      }
    }

    if (
      (data.businessUnit && typeof data.businessUnit === 'object' && !Array.isArray(data.businessUnit)) &&
      typeof data.businessUnit.id === 'number'
    ) {
      this.businessUnitId = data.businessUnit.id
    }

    return this
  }
}
