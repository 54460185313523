import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import InfinityPlugin from '@/plugins/infinity-plugin'
import InfinityPopover from 'mfe-shared/app/src/plugins/infinity-popover'
import 'mfe-shared/app/src/styles/_base.scss'
import { IconsPlugin } from 'bootstrap-vue'

Vue.config.productionTip = false
Vue.use(InfinityPlugin)
Vue.use(InfinityPopover)
Vue.use(IconsPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
