import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AuthUtil } from '@/utils/auth'
import Login from '@/views/Login.vue'
import Authenticated from '@/views/Authenticated.vue'
import Profile from '@/views/Authenticated/Profile.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Authenticated',
    component: Authenticated,
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: Profile
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(
  (to, from, next) => {
    if (to.meta && (to.meta.requiresAuth === false)) {
      if (AuthUtil.isAuthenticated) {
        next('/')

        return
      }

      next()

      return
    }

    if (AuthUtil.isAuthenticated === false) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })

      return
    }

    next()
  }
)

export default router
