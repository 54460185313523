






































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@/components/layout/Loader.vue'
import HubLogo from '@/components/layout/HubLogo.vue'
import { DropdownPlugin, NavbarPlugin, BAvatar } from 'bootstrap-vue'
import InstallationPicker from '@/components/InstallationPicker.vue'

Vue.use(NavbarPlugin)
Vue.use(DropdownPlugin)

@Component({
  components: {
    Loader,
    HubLogo,
    BAvatar,
    InstallationPicker
  }
})
export default class Authenticated extends Vue {
  private hasBooted = false

  async mounted () {
    if (this.$authService.hasBooted === false) {
      await this.$authService.boot()
    }

    this.hasBooted = true
  }

  async profileDetails () {
    await this.$router.push('/profile')
  }

  async logout () {
    try {
      this.hasBooted = false
      await this.$authService.logout()
      await this.$router.push('/login')
    } catch (e) {
      this.$bvToast.toast('We were unable to log you out. Please try again!', {
        title: 'Error',
        variant: 'danger',
        autoHideDelay: 5000,
        appendToast: true,
        solid: true,
        toaster: 'b-toaster-top-right'
      })
    }
  }
}
