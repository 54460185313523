












import { Component, Vue } from 'vue-property-decorator'
import ProfileModel from '@/models/profile'

@Component
export default class Profile extends Vue {
  get profile (): ProfileModel | null {
    return this.$authService.getProfile()
  }
}
