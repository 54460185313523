import Model, { apiToModelTransformer } from '@/models/base'
import { Api, JsonData } from '@/helpers/api'

export default class ReportPage extends Model {
  protected fieldDefinition = {
    [Api.Hub]: {
      id: 'id',
      name: 'name',
      igrps: 'installations'
    }
  }

  private id = 0
  private name = ''
  private installations: string[] = []

  getId () {
    return this.id
  }

  setId (id: number) {
    this.id = id

    return this
  }

  getName () {
    return this.name
  }

  setName (name: string) {
    this.name = name

    return this
  }

  getInstallations () {
    return this.installations
  }

  setInstallations (installations: string[]) {
    this.installations = installations

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return undefined
  }

  fromApiTransformer (data: JsonData, api: Api): ReportPage {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }
}
